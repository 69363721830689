<template>
<div class="section">

    <b-modal v-model="isShowPDFDialogLocal" scroll="keep" @close="closePDFDialog">
        <div class="card popupDialog">
            <div class="card-content">
                    <iframe id="pdf" class="pdfiFrame">
                        Your browser doesn't support iframes
                    </iframe> 
            </div>
        </div>
    </b-modal>        

</div>

</template>

<script>

import { PDFDocument, StandardFonts, PageSizes, degrees, grayscale, rgb } from 'pdf-lib'
// import { PageSizes } from 'pdf-lib'
// import { degrees } from 'pdf-lib'
// import { rgb } from 'pdf-lib'
import moment from 'moment'
import UserStore from "../../../store/UserStore"
// import * as fontkit from '@btielen/pdf-lib-fontkit';

export default {
    props: {
        isShowPDFDialog: Boolean,
        fetchStatus: String,
        profitAndLossData: {},
        summaryNumbers: {},
        fuelDeliveredByType: [],
        dateRange: []
    },

    data() {
        return {
            pdfDoc: {},
            helveticaFont: {},
            pageNumber: 0,
            page: {},
            sortedRecords: [],
            isShowPDFDialogLocal: false,
            totalBookValue: 0,
            totalBookCount: 0,
            totalSoldValue: 0,
            totalBalanceValue: 0,
            totalFuelSales: 0,
            totalMerchSales: 0,
            totalOtherIncome: 0,
            totalRevenue: 0,
            totalFuelCost: 0,
            totalMerchCost: 0,
            totalCostOfGoodsSold: 0,
            otherIncomeGroupedByVendor: [],
            totalSaleVolume: 0,
            inventoryPurchase: 0,
            fuelInventoryPurchase: 0,
            countryCurrencyFormat: UserStore.getters.getCountryCurrencyFormat
            // countryCurrencyFormat: Intl.NumberFormat('en-US',{style: 'currency', currency: 'USD'})
            // countryCurrencyFormat: Intl.NumberFormat('en-US', {
            //         style: 'currency',
            //         currency: UserStore.getters.getCountryCurrencyCode,

            //         // These options are needed to round to whole numbers if that's what you want.
            //         //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //         //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
            //     })
        }   

    },

    methods: {

        closePDFDialog() {
            this.$emit('close-pdf-dialog')
        },

        createPdf: async(gthis) => {

            gthis.pdfDoc = await PDFDocument.create()
            // const pdfDoc = gthis.pdfDoc
            gthis.pdfDoc.setAuthor('VEECLi Inc. All rights reserved.')
            gthis.pdfDoc.setTitle('Revenue Summary')
            gthis.pdfDoc.setSubject('support@veecli.com')
            gthis.pdfDoc.setCreator('VEECLi Inc - www.veecli.com')
            gthis.pdfDoc.setProducer('VEECLi Backoffice Software')

            gthis.helveticaFont = await gthis.pdfDoc.embedFont(StandardFonts.Helvetica)

            // gthis.pdfDoc.registerFontkit(fontkit)
            // const notoFontURL = '/fonts/NotoSans-Regular.otf'
            // const notoFontBytes = await fetch(notoFontURL).then(res => res.arrayBuffer())
            // gthis.helveticaFont  = await gthis.pdfDoc.embedFont(notoFontBytes)

            await gthis.pageHeader(gthis)
            var text = ""
            var row = 680

            row = await gthis.getNextRow(gthis,row, 1)
            gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'FUEL REVENUE')

            gthis.drawColHeadRight(gthis, row, 287, 9, "Gallons Sold")
            gthis.drawColHeadRight(gthis, row, 350, 9, "Price/Gal")
            gthis.drawColHeadRight(gthis, row, 420, 9, "Revenue %") 
            gthis.drawColHeadRight(gthis, row, 500, 9, "Sales Amount")            

            // row = await gthis.getNextRow(gthis,row)

            // Fuel Revenue
            for (const item of gthis.profitAndLossData.fuelSalesList) {
                
                row = await gthis.drawRowTextLeft(gthis, row, item.product)

                gthis.drawTextRight(gthis, row, 287, 9, item.saleVolume.toLocaleString('en-US'))

                text = (item.saleAmount / item.saleVolume).toString()
                gthis.drawTextRightCurrency(gthis, row, 350, 9, text)

                text = (item.saleAmount / gthis.totalRevenue * 100).toFixed(2) + "%"
                gthis.drawTextRight(gthis, row, 420, 9, text)

                gthis.drawTextRightCurrency(gthis, row, 500, 9, item.saleAmount)
                    
                row = await gthis.drawLine(gthis, row)
                    
            }

            row = await gthis.getNextRow(gthis,row, 3)

            gthis.drawRowHeadTextLeft(gthis, row, 45, 10, 'TOTAL FUEL REVENUE')

            gthis.drawTextRight(gthis, row, 287, 9, gthis.totalSaleVolume.toLocaleString('en-US'))

            gthis.drawTextRightCurrency(gthis, row, 350, 9, (gthis.totalFuelSales/gthis.totalSaleVolume).toFixed(2))

            text = (gthis.totalFuelSales / gthis.totalRevenue * 100).toFixed(2) + "%"
            gthis.drawTextRight(gthis, row, 420, 9, text)

            gthis.drawTextRightCurrency(gthis, row, 570, 10, gthis.totalFuelSales)
            gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)


            // Fuel Revenue

            // Merchandise Revenue

            row = await gthis.getNextRow(gthis,row, 5)

            gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'MERCHANDISE REVENUE ')   

            gthis.drawColHeadRight(gthis, row, 420, 9, "Revenue %") 
            gthis.drawColHeadRight(gthis, row, 500, 9, "Sales Amount")            

            for (const item of gthis.sortArrayByProp(gthis.profitAndLossData.merchSalesList,'storeMappingName', 'asc')) {                
                
                row = await gthis.drawRowTextLeft(gthis, row, item.storeMappingName)

                text = (item.netSalesValue / gthis.totalRevenue * 100).toFixed(2) + "%"
                gthis.drawTextRight(gthis, row, 420, 9, text)

                gthis.drawTextRightCurrency(gthis, row, 500, 9, item.netSalesValue)

                row = await gthis.drawLine(gthis, row)
                    
            }

            row = await gthis.getNextRow(gthis,row, 3)

            gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)

            gthis.drawRowHeadTextLeft(gthis, row, 45, 10, 'TOTAL MERCHANDISE REVENUE')

            text = (gthis.totalMerchSales / gthis.totalRevenue * 100).toFixed(2) + "%"
            gthis.drawTextRight(gthis, row, 420, 9, text)

            gthis.drawTextRightCurrency(gthis, row, 570, 10, gthis.totalMerchSales)
            
            // End Merch Revenue

            // Other Income

            row = await gthis.getNextRow(gthis,row, 5)
            gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'OTHER REVENUE')  

            gthis.drawColHeadRight(gthis, row, 420, 9, "Revenue %") 
            gthis.drawColHeadRight(gthis, row, 500, 9, "Income Amount")            

            for (const item of gthis.profitAndLossData.additionalIncomeByVendor) {
                
                row = await gthis.drawRowTextLeft(gthis, row, item.vendorName)

                text = (item.amount / gthis.totalRevenue * 100).toFixed(2) + "%"
                gthis.drawTextRight(gthis, row, 420, 9, text)

                gthis.drawTextRightCurrency(gthis, row, 500, 9, item.amount)

                row = await gthis.drawLine(gthis, row)
                    
            }

            row = await gthis.getNextRow(gthis,row, 3)

            gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)
            gthis.drawRowHeadTextLeft(gthis, row, 45, 10, 'TOTAL OTHER REVENUE')

            text = (gthis.totalOtherIncome / gthis.totalRevenue * 100).toFixed(2) + "%"
            gthis.drawTextRight(gthis, row, 420, 9, text)

            gthis.drawTextRightCurrency(gthis, row, 570, 10, gthis.totalOtherIncome)

            // End Other Income

            row = await gthis.getNextRow(gthis,row, 5)

            gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)
            gthis.drawRowHeadTextLeft(gthis, row, 30, 11, 'TOTAL REVENUE')

            text = (gthis.totalRevenue / gthis.totalRevenue * 100).toFixed(2) + "%"
            gthis.drawTextRight(gthis, row, 420, 9, text)

            gthis.drawTextRightCurrency(gthis, row, 570, 10, gthis.totalRevenue)

            // End Total Revenue

            // Cost of Goods Sold - Fuel

            row = await gthis.getNextRow(gthis,row, 5)

            gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'FUEL COST OF GOODS SOLD')

            gthis.drawColHeadRight(gthis, row, 287, 9, "Profit/Gal")
            gthis.drawColHeadRight(gthis, row, 350, 9, "Cost/Gal")
            gthis.drawColHeadRight(gthis, row, 420, 9, "Margin %") 
            gthis.drawColHeadRight(gthis, row, 500, 9, "Cost")            

            for (const item of gthis.profitAndLossData.fuelSalesList) {

                row = await gthis.drawRowTextLeft(gthis, row, item.product)

                gthis.drawTextRightCurrency(gthis, row, 287, 9, (item.saleAmount / item.saleVolume) - item.costPerGal)

                gthis.drawTextRightCurrency(gthis, row, 350, 9, item.costPerGal)

                text = (( item.saleAmount - item.costAmount) / item.costAmount * 100).toFixed(2)
                gthis.drawTextRight(gthis, row, 420, 9, text + " %")


                gthis.drawTextRightCurrency(gthis, row, 500, 9, item.costAmount)

                row = await gthis.drawLine(gthis, row)

            }

            row = await gthis.getNextRow(gthis,row, 5)

            gthis.drawTextRightCurrency(gthis, row, 350, 9, (gthis.totalFuelCost/gthis.totalSaleVolume))

            text = (( gthis.totalFuelSales - gthis.totalFuelCost) / gthis.totalFuelCost * 100).toFixed(2)
            gthis.drawTextRight(gthis, row, 420, 9, text + " %")

            gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)

            gthis.drawRowHeadTextLeft(gthis, row, 45, 10, 'TOTAL FUEL COST OF GOODS SOLD')
            // gthis.drawRowHeadTextLeft(gthis, row, 230, 7, 'Profit/gal. $' + gthis.summaryNumbers.fuelProfitPerGallon.toFixed(2)) + ""
            gthis.drawTextRightCurrency(gthis, row, 287, 9, gthis.summaryNumbers.fuelProfitPerGallon)
            gthis.drawTextRightCurrency(gthis, row, 570, 10, gthis.totalFuelCost)

            // Total Cost of Goods Sold - Fuel

            // Cost of Goods Sold - Merchandise

            row = await gthis.getNextRow(gthis,row, 5)
            gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'MERCHANDISE COST OF GOODS SOLD')
            
            gthis.drawColHeadRight(gthis, row, 420, 9, gthis.getSetting('profitCalculationMethod') + " %") 
            gthis.drawColHeadRight(gthis, row, 500, 9, "Cost")            

            for (const item of gthis.sortArrayByProp(gthis.profitAndLossData.merchSalesList,'storeMappingName', 'asc')) {

                row = await gthis.drawRowTextLeft(gthis, row, item.storeMappingName)

                if (gthis.getSetting('profitCalculationMethod') == "Markup") {
                    item.profitMargin = (item.cost > 0 ? ((item.netSalesValue - item.cost) / item.cost * 100 ).toFixed(2) : 0) 
                } else {
                    item.profitMargin = (item.netSalesValue > 0 ? ((item.netSalesValue - item.cost) / item.netSalesValue * 100 ).toFixed(2) : 0)
                }
                
                gthis.drawTextRight(gthis, row, 420, 9, item.profitMargin + " %")

                gthis.drawTextRightCurrency(gthis, row, 500, 9, item.cost)

                row = await gthis.drawLine(gthis, row)
                    
            }

            row = await gthis.getNextRow(gthis,row, 5)

            gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)

            if (gthis.getSetting('profitCalculationMethod') == "Markup") {
                text = (gthis.totalMerchCost > 0 ? ((gthis.totalMerchSales - gthis.totalMerchCost) / gthis.totalMerchCost * 100 ).toFixed(2) : 0) 
            } else {
                text = (gthis.totalMerchSales > 0 ? ((gthis.totalMerchSales - gthis.totalMerchCost) / gthis.totalMerchSales * 100 ).toFixed(2) : 0)
            }
            // text = (( gthis.totalMerchSales - gthis.totalMerchCost) / gthis.totalMerchCost * 100).toFixed(2)
            gthis.drawTextRight(gthis, row, 420, 9, text + " %")

            gthis.drawRowHeadTextLeft(gthis, row, 45, 10, 'TOTAL MERCHANDISE COST OF GOODS SOLD')

            gthis.drawTextRightCurrency(gthis, row, 570, 10, gthis.totalMerchCost)

            // End Total Cost of Goods Sold - Merchandise

            row = await gthis.getNextRow(gthis,row, 5)

            gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)

            gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'TOTAL COST OF GOODS SOLD')

            text = (( (gthis.totalMerchSales + gthis.totalFuelSales) - gthis.totalCostOfGoodsSold) / gthis.totalCostOfGoodsSold * 100).toFixed(2)
            gthis.drawTextRight(gthis, row, 420, 9, text + " %")

            gthis.drawTextRightCurrency(gthis, row, 570, 10, gthis.totalCostOfGoodsSold)

            // End of Total Cost of Goods Sold

            // Beg Gross Profit

            row = await gthis.getNextRow(gthis,row, 5)

            gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)

            gthis.drawRowHeadTextLeft(gthis, row, 30, 11, 'GROSS PROFIT')

            text = ( gthis.grossProfit / (gthis.totalMerchSales + gthis.totalFuelSales) * 100).toFixed(2)
            gthis.drawTextRight(gthis, row, 420, 11, text + " %")

            gthis.drawTextRightCurrency(gthis, row, 570, 11, gthis.grossProfit)
            
            // End Gross Profit

            // Expenses

            row = await gthis.getNextRow(gthis,row, 5)
            gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'OPERATING EXPENSES')

            gthis.drawColHeadRight(gthis, row, 420, 9, "Revenue %") 
            gthis.drawColHeadRight(gthis, row, 500, 9, "Expense Amount")            

            for (const item of gthis.profitAndLossData.expensesList) {

                row = await gthis.drawRowTextLeft(gthis, row, item.expenseTypeName)

                text = (item.amount / gthis.totalRevenue * 100).toFixed(2)
                gthis.drawTextRight(gthis, row, 420, 9, text + " %")
                
                gthis.drawTextRightCurrency(gthis, row, 500, 9, item.amount)

                row = await gthis.drawLine(gthis, row)
                    
            }

            row = await gthis.getNextRow(gthis,row, 3)

            text = (gthis.summaryNumbers.totalOperatingExpense / gthis.totalRevenue * 100).toFixed(2)
            gthis.drawTextRight(gthis, row, 420, 9, text + " %")
            gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)
            gthis.drawRowHeadTextLeft(gthis, row, 45, 10, 'TOTAL OPERATING EXPENSE')

            gthis.drawTextRightCurrency(gthis, row, 570, 10, gthis.summaryNumbers.totalOperatingExpense)

            // End Expenses

            // Beg Net Profit

            row = await gthis.getNextRow(gthis,row, 5)

            text = (gthis.netProfit / gthis.totalRevenue * 100).toFixed(2)
            gthis.drawTextRight(gthis, row, 420, 9, text + " %")

            gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)

            gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'NET PROFIT')

            gthis.drawTextRightCurrency(gthis, row, 570, 10, gthis.netProfit)
            
            // End Net Profit
            
            // Beg Sales Tax

            row = await gthis.getNextRow(gthis,row, 5)
            gthis.drawColHeadRight(gthis, row, 420, 9, "Revenue %") 
            gthis.drawColHeadRight(gthis, row, 500, 9, "Tax Amount")            

            gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'SALES TAX')

            row = await gthis.drawRowTextLeft(gthis, row, "Sales Tax Collected (add)")

            text = (gthis.summaryNumbers.salesTax.totalTaxes / gthis.totalRevenue * 100).toFixed(2)
            gthis.drawTextRight(gthis, row, 420, 9, text + " %")
            gthis.drawTextRightCurrency(gthis, row, 500, 9, gthis.summaryNumbers.salesTax.totalTaxes)

            row = await gthis.drawLine(gthis, row)


            row = await gthis.drawRowTextLeft(gthis, row, "Sales Tax Paid (subtract)")

            text = (gthis.summaryNumbers.salesTax.salesTaxPaid / gthis.totalRevenue * 100).toFixed(2)
            gthis.drawTextRight(gthis, row, 420, 9, text + " %")
            gthis.drawTextRightCurrency(gthis, row, 500, 9, gthis.summaryNumbers.salesTax.salesTaxPaid)

            row = await gthis.drawLine(gthis, row)
         
            // End Sales Tax

            // Beg Net Profit

            row = await gthis.getNextRow(gthis,row, 5)

            gthis.drawTotalRectangle(gthis, row-3, 30, 550, 25, 0.1)

            gthis.drawRowHeadTextLeft(gthis, row, 30, 12, 'NET PROFIT AFTER TAX')

            var netSalesTax = gthis.summaryNumbers.salesTax.salesTaxPaid - gthis.summaryNumbers.salesTax.totalTaxes
            var netProfitAfterSalesTax = gthis.netProfit - netSalesTax
            text = (netProfitAfterSalesTax / gthis.totalRevenue * 100).toFixed(2)
            gthis.drawTextRight(gthis, row, 420, 12, text + " %")

            gthis.drawTextRightCurrency(gthis, row, 570, 12, netProfitAfterSalesTax)
            
            // End Net Profit

            // Start Lottery Details
            await gthis.pageHeader(gthis, "LOTTERY SALES AND INVENTORY PURCHASE")
            text = ""
            row = 680

            row = await gthis.getNextRow(gthis,row, 5)
            gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'LOTTERY SALES')
            row = await gthis.getNextRow(gthis,row, 1)
            gthis.drawColHeadRight(gthis, row, 500, 9, "Amount") 


            // row = await gthis.getNextRow(gthis,row)
            row = await gthis.drawRowTextLeft(gthis, row, "Instant Lottery Sales")
            text = gthis.profitAndLossData.lottery.instantLotterySales
            gthis.drawTextRightCurrency(gthis, row, 500, 9, text)
            row = await gthis.drawLine(gthis, row)

            // row = await gthis.getNextRow(gthis,row)
            row = await gthis.drawRowTextLeft(gthis, row, "Instant Lottery Payout")
            text = gthis.profitAndLossData.lottery.instantLotteryPaidOut * -1
            gthis.drawTextRightCurrency(gthis, row, 500, 9, text)
            row = await gthis.drawLine(gthis, row)

            row = await gthis.drawRowTextLeft(gthis, row, "Online Lottery Sales")
            text = gthis.profitAndLossData.lottery.onlineLotterySales
            gthis.drawTextRightCurrency(gthis, row, 500, 9, text)
            row = await gthis.drawLine(gthis, row)


            if (gthis.profitAndLossData.lottery.totalOnlineLottoCancel == null) {
                gthis.profitAndLossData.lottery.totalOnlineLottoCancel = 0.00
            }

            if (gthis.profitAndLossData.totalOnlineLottoCancel != 0) {
                // row = await gthis.getNextRow(gthis,row)
                row = await gthis.drawRowTextLeft(gthis, row, "Included in POS Cash / Online Cancel ")
                text = gthis.profitAndLossData.totalOnlineLottoCancel * -1
                gthis.drawTextRightCurrency(gthis, row, 500, 9, text)
                row = await gthis.drawLine(gthis, row)
            }
            
            // row = await gthis.getNextRow(gthis,row)
            row = await gthis.drawRowTextLeft(gthis, row, "Online Lottery Payout")
            text = gthis.profitAndLossData.lottery.onlineLotteryPaidOut * -1
            gthis.drawTextRightCurrency(gthis, row, 500, 9, text)
            row = await gthis.drawLine(gthis, row)

            // row = await gthis.getNextRow(gthis,row)
            row = await gthis.drawRowTextLeft(gthis, row, "Machine Instant Lottery Sales")
            text = gthis.profitAndLossData.totalLotteryMachineWithdraw
            gthis.drawTextRightCurrency(gthis, row, 500, 9, text)
            row = await gthis.drawLine(gthis, row)

            row = await gthis.getNextRow(gthis,row, 5)

            gthis.drawTotalRectangle(gthis, row-3, 30, 550, 25, 0.1)

            gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'NET LOTTERY')
            text = gthis.profitAndLossData.lottery.onlineLotterySales -
                    gthis.profitAndLossData.lottery.onlineLotteryCanceled -
                    gthis.profitAndLossData.lottery.onlineLotteryPaidOut +
                    gthis.profitAndLossData.lottery.instantLotterySales -
                    gthis.profitAndLossData.lottery.instantLotteryPaidOut +
                    gthis.profitAndLossData.totalLotteryMachineWithdraw

            gthis.drawTextRightCurrency(gthis, row, 570, 10, text)

            row = await gthis.getNextRow(gthis,row,2)
            row = await gthis.drawRowTextLeft(gthis, row, "Machine Lottery Cash Taken")
            text = gthis.profitAndLossData.totalLotteryMachineSold
            gthis.drawTextRightCurrency(gthis, row, 500, 9, text)
            row = await gthis.drawLine(gthis, row)

            // End Lottery Details

            // Start Fuel Invoiced
    
            row = await gthis.getNextRow(gthis,row, 6)
            gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'FUEL INVOICE')

            // row = await gthis.getNextRow(gthis,row)

            // row = await gthis.drawRowTextLeft(gthis, row, " ")

            // // text  = fuel.product[0].toUpperCase() + fuel.product.substr(1).toLowerCase()
            // gthis.drawTextLeft(gthis, row, 100, 9, "TOTAL FUEL INVOICED")
            
            // gthis.drawTextRight(gthis, row, 500, 9, gthis.profitAndLossData.totalFuelVolumePurchased.toString()+" gal.")
            // totalFuelDelivery += fuel.deliverdVol

            var fuelPurchase = gthis.profitAndLossData.inventoryExpenseList.filter((inventory) => {
                return inventory.deptMapping === 'Fuel'
            })

            var totalFuelPurchase = 0.00
            fuelPurchase.forEach(item => {
                totalFuelPurchase += item.expenseCost
            })

            row = await gthis.getNextRow(gthis,row, 5)
            gthis.drawTotalRectangle(gthis, row-3, 30, 550, 25, 0.1)
            gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'TOTAL FUEL INVOICED')
            gthis.drawTextRight(gthis, row, 500, 10, gthis.profitAndLossData.totalFuelVolumePurchased.toLocaleString('en-US')+" gal.")            
            gthis.drawTextRightCurrency(gthis, row, 570, 10, totalFuelPurchase)

            // End Fuel Invoiced

            // Start Fuel Delivery Details

            row = await gthis.getNextRow(gthis,row, 6)
            gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'FUEL DELIVERY')
            row = await gthis.getNextRow(gthis,row, 1)
            gthis.drawColHeadRight(gthis, row, 500, 9, "Gallons Delivered") 

            var totalFuelDelivery = 0.00

            for (const fuel of gthis.fuelDeliveredByType) {

                // row = await gthis.drawRowTextLeft(gthis, row, " ")

                text  = fuel.product[0].toUpperCase() + fuel.product.substr(1).toLowerCase()
                // gthis.drawTextLeft(gthis, row, 100, 9, text)
                row = await gthis.drawRowTextLeft(gthis, row, text)
                
                gthis.drawTextRight(gthis, row, 500, 9, fuel.deliverdVol.toLocaleString('en-US'))
                totalFuelDelivery += fuel.deliverdVol

                row = await gthis.drawLine(gthis, row)                

            }

            row = await gthis.getNextRow(gthis,row, 5)
            gthis.drawTotalRectangle(gthis, row-3, 30, 550, 25, 0.1)
            gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'TOTAL FUEL DELIVERY')
            gthis.drawTextRight(gthis, row, 570, 10, totalFuelDelivery.toLocaleString('en-US'))            

            // End Fuel Delivery Details


            // Start Inventory Purchased
            row = await gthis.getNextRow(gthis,row, 6)
            gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'INVENTORY PURCHASE')
            
            var totalInventoryPurchase = 0.00
            gthis.drawColHeadRight(gthis, row, 500, 9, "Purchase Amount") 
            for (const inventory of gthis.inventoryPurchase) {

                // row = await gthis.drawRowTextLeft(gthis, row, " ")

                text  = inventory.deptMapping[0].toUpperCase() + inventory.deptMapping.substr(1).toLowerCase()
                // gthis.drawTextLeft(gthis, row, 100, 9, text)
                row = await gthis.drawRowTextLeft(gthis, row, text)

                gthis.drawTextRightCurrency(gthis, row, 500, 9, inventory.expenseCost.toString())

                totalInventoryPurchase += inventory.expenseCost
 
                row = await gthis.drawLine(gthis, row)                

            }

            row = await gthis.getNextRow(gthis,row, 5)
            gthis.drawTotalRectangle(gthis, row-3, 30, 550, 25, 0.1)
            gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'TOTAL INVENTORY PURCHASED')
            gthis.drawTextRightCurrency(gthis, row, 570, 10, totalInventoryPurchase)           

            // End Inventory Purchased

            // Start Deposits/Disbursements

            var totalDeposit = gthis.profitAndLossData.deposits.bankDepositGeneral + 
                                gthis.profitAndLossData.deposits.bankDepositLottery + 
                                gthis.profitAndLossData.deposits.bankDepositAtm +
                                gthis.profitAndLossData.totalCashLoadedInAtm

            if (totalDeposit > 0) {
                row = await gthis.getNextRow(gthis,row, 6)
                gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'DEPOSITS/DISBURSEMENTS')
                
                row = await gthis.getNextRow(gthis,row, 1)
                gthis.drawColHeadRight(gthis, row, 500, 9, "Deposit Amount") 
                row = await gthis.drawRowTextLeft(gthis, row, "Deposit - General Account")
                gthis.drawTextRightCurrency(gthis, row, 500, 9, gthis.profitAndLossData.deposits.bankDepositGeneral.toString())
                row = await gthis.drawLine(gthis, row)   

                row = await gthis.drawRowTextLeft(gthis, row, "Deposit - Lottery Account")
                gthis.drawTextRightCurrency(gthis, row, 500, 9, gthis.profitAndLossData.deposits.bankDepositLottery.toString())
                row = await gthis.drawLine(gthis, row)

                row = await gthis.drawRowTextLeft(gthis, row, "Other Cash Disbursements")
                gthis.drawTextRightCurrency(gthis, row, 500, 9, gthis.profitAndLossData.deposits.bankDepositAtm.toString())
                row = await gthis.drawLine(gthis, row)

                row = await gthis.drawRowTextLeft(gthis, row, "Cash Loaded in ATM")
                gthis.drawTextRightCurrency(gthis, row, 500, 9, gthis.profitAndLossData.totalCashLoadedInAtm.toString())
                row = await gthis.drawLine(gthis, row)

                row = await gthis.getNextRow(gthis,row, 5)
                gthis.drawTotalRectangle(gthis, row-3, 30, 550, 25, 0.1)
                gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'DEPOSITS/DISBURSEMENTS')
                gthis.drawTextRightCurrency(gthis, row, 570, 10, (totalDeposit).toString() )

            }
            // End  Deposits/Disbursements

            // Start Cigarette

            if (totalDeposit > 0) {
                row = await gthis.getNextRow(gthis,row, 6)
                gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'CIGARETTE COUNT')
                
                row = await gthis.getNextRow(gthis,row, 1)
                gthis.drawColHeadRight(gthis, row, 500, 9, "Count") 
                row = await gthis.drawRowTextLeft(gthis, row, "Open Count")
                gthis.drawTextRight(gthis, row, 500, 9, gthis.profitAndLossData.previousCigaretteCloseCount.toString())
                row = await gthis.drawLine(gthis, row)   

                row = await gthis.drawRowTextLeft(gthis, row, "Buy Count")
                gthis.drawTextRight(gthis, row, 500, 9, gthis.profitAndLossData.cigBuyCount.toString())
                row = await gthis.drawLine(gthis, row)

                row = await gthis.drawRowTextLeft(gthis, row, "Sale Count")
                gthis.drawTextRight(gthis, row, 500, 9, (gthis.profitAndLossData.cigDeptSaleItemCount * -1).toString())
                row = await gthis.drawLine(gthis, row)

                var cigBalance = gthis.profitAndLossData.previousCigaretteCloseCount 
                                            + gthis.profitAndLossData.cigBuyCount
                                            - gthis.profitAndLossData.cigDeptSaleItemCount

                row = await gthis.drawRowTextLeft(gthis, row, "Balance Count")
                gthis.drawTextRight(gthis, row, 500, 9, cigBalance.toString())
                row = await gthis.drawLine(gthis, row)

                row = await gthis.drawRowTextLeft(gthis, row, "Close Count")
                gthis.drawTextRight(gthis, row, 500, 9, gthis.profitAndLossData.cigCloseCount.toString())
                row = await gthis.drawLine(gthis, row)

                var cigVariance = (gthis.profitAndLossData.previousCigaretteCloseCount 
                                            + gthis.profitAndLossData.cigBuyCount
                                            - gthis.profitAndLossData.cigDeptSaleItemCount 
                                            - gthis.profitAndLossData.cigCloseCount) * -1
                row = await gthis.getNextRow(gthis,row, 5)
                gthis.drawTotalRectangle(gthis, row-3, 30, 550, 25, 0.1)
                gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'Variance')
                gthis.drawTextRight(gthis, row, 570, 10, cigVariance.toString() )

            }
            // End  Deposits/Disbursements

            // Start Cigarette Amount
            row = await gthis.getNextRow(gthis,row, 4)
            if (gthis.profitAndLossData.cigDeptSales > 0 || gthis.profitAndLossData.cigBuyAmount > 0) {
                row = await gthis.getNextRow(gthis,row, 6)
                gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'CIGARETTE AMOUNT')
                row = await gthis.getNextRow(gthis,row, 1)
                gthis.drawColHeadRight(gthis, row, 500, 9, "Amount") 
                
                if (gthis.profitAndLossData.cigDeptSales > 0) {
                    row = await gthis.drawRowTextLeft(gthis, row, "Sales")
                    gthis.drawTextRightCurrency(gthis, row, 500, 9, gthis.profitAndLossData.cigDeptSales.toString())
                    row = await gthis.drawLine(gthis, row)   

                    row = await gthis.drawRowTextLeft(gthis, row, "Average Price")
                    gthis.drawTextRightCurrency(gthis, row, 500, 9, (gthis.profitAndLossData.cigDeptSales / gthis.profitAndLossData.cigDeptSaleItemCount ).toString())
                    row = await gthis.drawLine(gthis, row)
                }
                if (gthis.profitAndLossData.cigBuyAmount > 0) {
                    row = await gthis.drawRowTextLeft(gthis, row, "Purchase")
                    gthis.drawTextRightCurrency(gthis, row, 500, 9, gthis.profitAndLossData.cigBuyAmount.toString())
                    row = await gthis.drawLine(gthis, row)
            
                    row = await gthis.drawRowTextLeft(gthis, row, "Average Cost")
                    gthis.drawTextRightCurrency(gthis, row, 500, 9, (gthis.profitAndLossData.cigBuyAmount / gthis.profitAndLossData.cigBuyCount).toString())
                    row = await gthis.drawLine(gthis, row)
                }

            }
            // End  Cigarette Amount

            // Start Other

            if (gthis.profitAndLossData.totalCarWashSold > 0 ||gthis.profitAndLossData.totalPhoneCardSold > 0 ) {
                row = await gthis.getNextRow(gthis,row, 6)
                gthis.drawRowHeadTextLeft(gthis, row, 30, 10, 'OTHER')
                
                row = await gthis.getNextRow(gthis,row, 1)
                gthis.drawColHeadRight(gthis, row, 500, 9, "Sold") 
                if (gthis.profitAndLossData.totalCarWashSold > 0) {
                    row = await gthis.drawRowTextLeft(gthis, row, "Car Wash")
                    gthis.drawTextRight(gthis, row, 500, 9, gthis.profitAndLossData.totalCarWashSold.toString())
                    row = await gthis.drawLine(gthis, row)   
                }

                if (gthis.profitAndLossData.totalPhoneCardSold > 0) {
                    row = await gthis.drawRowTextLeft(gthis, row, "Phone Cards")
                    gthis.drawTextRight(gthis, row, 500, 9, gthis.profitAndLossData.totalPhoneCardSold.toString())
                    row = await gthis.drawLine(gthis, row)
                }

            }
            // End  Other

            // Create PDF Document
            const pdfDataUri = await gthis.pdfDoc.saveAsBase64({ dataUri: true });
            document.getElementById('pdf').src = pdfDataUri;
        },

        pageHeader: async(gthis, titleText)  => {

            if (!titleText) {
                titleText = "PROFIT AND LOSS STATEMENT"
            }
            gthis.pageNumber++

            gthis.page = gthis.pdfDoc.addPage(PageSizes.Letter);
            
            var storeName = UserStore.getters.getActiveStore.storeName
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(storeName, 14);

            gthis.page.drawText(storeName, { 
                x: 300 - (textWidth/2), 
                y: 750, 
                size: 14, 
                })

            var row = 725
            var reportHead = titleText
            textWidth = gthis.helveticaFont.widthOfTextAtSize(reportHead, 13);

            gthis.page.drawText(reportHead, { 
                x: 300 - (textWidth/2), 
                y: row, 
                size: 15, 
                })            

            row = 700
            var dateRange = moment(gthis.dateRange[0]).format('MM/DD/YYYY') + " - " + moment(gthis.dateRange[1]).format('MM/DD/YYYY')
            textWidth = gthis.helveticaFont.widthOfTextAtSize(dateRange, 11);

            gthis.page.drawText(dateRange, { 
                x: 300 - (textWidth/2), 
                y: row, 
                size: 11, 
                })
            await gthis.pageFooter(gthis)                         
                
        },

        veecliLogo: async(gthis) => {
            const pngUrl = '/images/veecli-logo.png'
            const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer())

            const pngImage = await gthis.pdfDoc.embedPng(pngImageBytes)
            const pngDims = pngImage.scale(0.08)
            
            gthis.page.drawImage(pngImage, {
                x: 300,
                y: 18,
                width: pngDims.width,
                height: pngDims.height,
                rotate: degrees(0),
                opacity: 0.75,
            })
            gthis.page.drawText(" VEECLi.com", { 
                x: 308, 
                y: 20, 
                size: 9,
                TextAlignment: 2
                })                    
        },

        pageFooter: async(gthis) => {

            let timeNow = Date()
            timeNow = moment(timeNow).format('MM/DD/YYYY hh:mm a')

            gthis.page.drawText(timeNow, { 
                x: 20, 
                y: 20, 
                size: 9,
                TextAlignment: 2
                })

            var textWidth = gthis.helveticaFont.widthOfTextAtSize('Page ' + gthis.pageNumber, 9)
            gthis.page.drawText('Page ' + gthis.pageNumber, { 
                x: 590 - textWidth, 
                y: 20, 
                size: 9,
                TextAlignment: 2
                })
            await gthis.veecliLogo(gthis)
        },

        getNextRow: async(gthis,row, count) => {
  
            if (!count) count = 1
            if (row > 75) {
                return row - (5 * count)
            } else {
                await gthis.pageHeader(gthis)

                // Set Line to start records
                return 680
            }
            
        },

        drawRowHeadTextLeft: async (gthis, row, colStart, fontSize, text) => {
            gthis.page.drawText(text, { 
                x: colStart, 
                y: row, 
                size: fontSize, 
            })
            return row
        },

        drawRowTextLeft: async (gthis, row, text) => {
            row = await gthis.getNextRow(gthis,row, 2)
            // text = text[0].toUpperCase() + text.substr(1).toLowerCase()
            if (text === "InsideSales") { text = "Inside Sales"}
            if (text === "BeerWine") { text = "Beer / Wine"}
            text = gthis.toTitleCase(text)

            gthis.page.drawText(text, { 
                x: 45, 
                y: row, 
                size: 9, 
            })
            return row
        },

        drawTextLeft: async (gthis, row, colStart, fontSize, text) => {
            gthis.page.drawText(text, { 
                x: colStart, 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },

        drawTextRight: async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
            gthis.page.drawText(text, { 
                x: colStart * 1 - (textWidth), 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },

        drawColHeadRight:async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
            gthis.page.drawText(text, { 
                x: colStart * 1 - (textWidth), 
                y: row+3, 
                size: fontSize,
                TextAlignment: 2 
            })
            gthis.page.drawLine({
                start: { x: colStart - textWidth - 20, y: row },
                end: { x: colStart, y: row  },
                thickness: 1,
                opacity: 0.4,
            })
            
        },

        drawTextRightGal: async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(text+" gal.", fontSize)
            gthis.page.drawText(text+" gal.", { 
                x: colStart * 1 - (textWidth), 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },

        drawTextRightCurrency: async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(gthis.countryCurrencyFormat.format(text), fontSize)
            gthis.page.drawText(gthis.countryCurrencyFormat.format(text), { 
                x: colStart * 1 - (textWidth), 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },        

        drawLine: async (gthis, row) => {
            row = await gthis.getNextRow(gthis,row, 1)
            gthis.page.drawLine({
                start: { x: 45, y: row },
                end: { x: 500, y: row },
                thickness: 1,
                opacity: 0.2,
            })
            return row
        },

        drawTotalRectangle: async (gthis, row, colStart, width, height, opacity) => {
            gthis.page.drawRectangle({
                x: colStart-4,
                y: row-4,
                width: width,
                height: height,
                rotate: degrees(0),
                borderWidth: 0,
                borderColor: grayscale(0.5),
                color: rgb(0, 0, 0),
                opacity: opacity,
                borderOpacity: 0.75,
            })
        },

        calculateTotals() {
                       
            this.totalFuelSales = this.profitAndLossData.fuelSalesList.reduce((sum, item) => sum + parseFloat(item.saleAmount),0,)
            this.totalSaleVolume = this.profitAndLossData.fuelSalesList.reduce((sum, item) => sum + parseFloat(item.saleVolume),0,)
            this.totalFuelCost = this.profitAndLossData.fuelSalesList.reduce((sum, item) => sum + parseFloat(item.costAmount),0,)
            this.profitAndLossData.merchSalesList = this.profitAndLossData.merchSalesList.filter(item => {
                return item.storeMapping != "Fuel"
            })
            this.totalMerchSales = this.profitAndLossData.merchSalesList.reduce((sum, item) => sum + parseFloat(item.netSalesValue),0,)
            this.totalMerchCost = this.profitAndLossData.merchSalesList.reduce((sum, item) => sum + parseFloat(item.cost),0,)
        
            this.totalOtherIncome = this.profitAndLossData.additionalIncomeList.reduce((sum, item) => sum + parseFloat(item.amount),0,)
            this.totalRevenue = parseFloat(this.totalFuelSales) + parseFloat(this.totalMerchSales) + parseFloat(this.totalOtherIncome)
            this.totalCostOfGoodsSold = parseFloat(this.totalMerchCost) + parseFloat(this.totalFuelCost)
            this.grossProfit = parseFloat(this.totalRevenue) - parseFloat(this.totalCostOfGoodsSold)
            this.netProfit = parseFloat(this.grossProfit) - parseFloat(this.summaryNumbers.totalOperatingExpense)
            this.inventoryPurchase = this.profitAndLossData.inventoryExpenseList.filter((inventory) => {
                return inventory.deptMapping !== 'Fuel'
            })
            this.fuelInventoryPurchase = this.profitAndLossData.inventoryExpenseList.filter((inventory) => {
                return inventory.deptMapping === 'Fuel'
            })
        },

        async loadData() {
            this.isShowPDFDialogLocal = true
            // this.groupedDeliveryByFuel()
            this.calculateTotals()
            this.createPdf(this)
            this.pageNumber = 0
        }
    },
  
}
</script>


<style scoped>

.popupDialog {
    /* position:absolute; */
    width:100%;
    height: 100%; 
    max-height: 1000px;
    min-height: 900px;     
}

.pdfiFrame {
    position:absolute; 
    top:0; 
    left:0; 
    bottom:0; 
    /* right:0;  */
    width:100%; 
    height:100%; 
    /* border:none; */
    /* margin-top: 50px; */
    /* margin-bottom: 50px; */
    /* padding:0;  */
    overflow:hidden; 
    /* z-index:999999;    */
}
div{
    height: 100%;
}
</style>